body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.wrapper {
  max-width: 1350px;
  padding: 0px 60px;
  margin: 50px auto;
  display: flex;
  flex-wrap: wrap;
  gap: 2em;
}

.wrapper img {
  cursor: pointer;
}

/* modal */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(27, 27, 27, 0.541);
  display: flex;
  align-items: center;
}
.overlay img {
  display: block;
  max-width: 60%;
  max-height: 80%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
}
.overlay > span {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 30px;
  color: #ffffff;
  z-index: 999;
  cursor: pointer;
}
.overlay-arrows_left {
  display: flex;
  background-color: #6d6d6d;
  justify-content: space-between;
  position: absolute;
  top: 50%;
  width: 50px;
  height: 50px;
  left: 0;
  z-index: 999;
}

.overlay-arrows_left svg {
  width: 50px;
  height: 50px;
}

.overlay-arrows_right svg {
  width: 50px;
  height: 50px;
}

.overlay-arrows_right {
  display: flex;
  background-color: #6d6d6d;
  justify-content: space-between;
  position: absolute;
  right: 0;
  width: 50px;
  height: 50px;
  top: 50%;
  z-index: 999;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

header {
  display: flex;
  flex-flow: row wrap;
  align-items: baseline;
  justify-content: center;
  margin: 16px 8px;
}

header h1 {
  font-size: 1.4rem;
  font-weight: 500;
  margin: 0;
  padding: 8px;
  text-align: center;
}

header a {
  font-size: 1.4rem;
  font-weight: 500;
  margin: 0;
  padding: 8px;
  color: rgb(0, 112, 243);
  text-decoration: none;
}

header a:hover {
  text-decoration: underline;
}

main {
  padding: 5px;
  margin: 0 auto;
  max-width: 1200px;
}

@media all and (min-width: 300px) {
  main {
    padding: 10px;
  }
}

@media all and (min-width: 600px) {
  main {
    padding: 15px;
  }
}

@media all and (min-width: 1200px) {
  main {
    padding: 20px;
  }
}
