* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body,
html {
  background-image: url(./IMG_0473.jpg);
  background-color: 882021;
  height: 100%;
  /* border-width: 100%;
  margin: 0px;
  padding: 0; */
  transition: 0.5s;
  /* filter: brightness(1); */
}

/* #background {
  padding: 0;
  margin-left: 50%;
  float: right;
} */

@import url("https://unpkg.com/css.gg@2.0.0/icons/css/close.css");

.centered {
  display: flex;
  width: 100%;
  max-width: 60%;
  margin: -1rem auto;
  justify-content: center;
}

.center {
  margin: auto;
  position: relative;
  width: 50%;
}

.carousel-item > img {
  width: 100%;
}

.hamburger {
  margin-left: 3rem;
}

.gg-close-r {
  box-sizing: border-box;
  position: relative;
  display: block;
  transform: scale(var(--ggs, 1));
  width: 22px;
  height: 22px;
  border: 2px solid;
  border-radius: 4px;
}

.gg-close-r::after,
.gg-close-r::before {
  content: "";
  display: block;
  box-sizing: border-box;
  position: absolute;
  width: 12px;
  height: 2px;
  background: currentColor;
  transform: rotate(45deg);
  border-radius: 5px;
  top: 8px;
  left: 3px;
}

.gg-close-r::after {
  transform: rotate(-45deg);
}

.scroll-effect {
  animation: fade-in linear;
  animation-timeline: view();
  animation-range-start: cover;
  animation-range-end: contain;
}

@keyframes fade-in {
  from {
    scale: 0.8;
    opacity: 0;
  }
  to {
    scale: 1;
    opacity: 1;
  }
}

body:hover {
  filter: brightness(1);
  transform: translateZ(300px);
}

.press {
  /* margin-top: 20px; */
  background-image: url(./assets/Reviewbackground3.png);
  background-repeat: no-repeat;
  background-position: 50% -20%;
  width: 100%;
  height: 750px;
}

#pngEffects {
  filter: brightness(0.5);
}
#pngEffects:hover {
  filter: brightness(1);
}

.btnTransform:link,
.btnTransform:visited {
  color: rgb(240, 173, 78);
  transition: all 0.2;
  position: relative;
  border-radius: 10rem;
}
.btnTransform:hover {
  background: rgba(241, 102, 98, 0.2);
  transform: translateY(-0.3rem);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.btnTransform:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
  background-color: #f96a6a;
}
.btnTransform::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 10rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s;
}
.btnTransform:active {
  transform: translate(-1px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}

.imgTransform:hover {
  background: rgba(241, 102, 98, 0.2);
  transform: translateY(-0.3rem);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}
.imgTransform::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s;
  border-radius: 0rem;
}
.imgTransform:active {
  transform: translate(-1px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@font-face {
  font-family: "matura-mt-script-capitals-cufonfonts";
  src: url(./AnyConv.com__MATURASC_1.woff);
  font-display: auto;
}

h1,
h2 {
  text-align: center;
  font-family: "matura-mt-script-capitals-cufonfonts";
  font-style: italic;
  margin-top: 1rem;
  padding-bottom: 0px;
  margin-bottom: 0.75rem;
  color: #ffff66;
}

.home {
  margin-top: 1rem;
  /*padding-bottom: 0px;
  text-align: center;*/
  animation-name: moveInRight;
  animation-duration: 9s;
  color: #ffff66;
  box-shadow: none;
}

.opening {
  margin-top: -10px;
  line-height: 98%;
  text-align: right;
  animation-name: moveInLeft;
  animation-duration: 3s;
  background: transparent;
  box-shadow: none;
}

h1 em {
  font-size: 3.2rem;
  padding-bottom: 0.1rem;

  color: #ffff66;
  text-align: left;
  animation-name: moveInLeft;
  animation-duration: 3s;
}

h5,
h6 {
  animation-name: moveInLeft;
  animation-duration: 3s;
}

h4 {
  color: #ffff66;

  animation-name: moveInLeft;
  animation-duration: 3s;
}

.navv {
  background-color: rgb(217, 83, 79, 0.4);
  margin-bottom: 5px;
  width: 100% !important;
  margin-right: 0px;
  margin-left: 0px;
  margin-top: 0px;
  position: relative;
  text-align: center;
}

Nav .container {
  margin-right: 0;
  padding-right: 0;
  animation: moveInButton 0.75s ease-out 0.75s;
  animation-fill-mode: backwards;
}

.navv::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.navv {
  overflow-y: scroll; /* Add the ability to scroll */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

#navbarBrand {
  margin-left: 1rem;
}

#navbarBrand:hover {
  background-color: #fdfdaf;
}

.alertyellow {
  color: #ffff66;
}

.scroll {
  .scroll {
    min-height: 100%;
    overflow: scroll;
  }
}

h6 {
  font-size: 1.2rem;
  animation-name: moveInRight;
  animation-duration: 2s;
}

.larger {
  font-size: 1.2rem;

  animation-duration: 5s;
}
.smaller {
  font-size: 1rem;
  animation-name: moveInRight;
  animation-duration: 5s;
}
.larger_mod {
  font-size: 120%;
  animation-name: moveInRight_mod;
  animation-duration: 10s;
}
a {
  -webkit-transition: color 3s;
  transition: color 3s;
}

a:hover {
  color: #ffff66;
}
.press {
  color: whitesmoke;
}
.press:hover {
  color: #ffff66;
}
.btnTransform:link,
.btnTransform:visited {
  color: rgb(240, 173, 78);
  transition: all 0.2;
  position: relative;
  border-radius: 10rem;
}

.btnTransform:hover,
.imgTransform:hover {
  background: rgba(241, 102, 98, 0.2);
  transform: translateY(-0.3rem);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

.btnTransform:hover::after,
.imgTransform:hover::after {
  transform: scaleX(1.4) scaleY(1.6);
  opacity: 0;
  background-color: #ffff66;
}

.btnTransform::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 10rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s;
}

.imgTransform::after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all 0.4s;
  border-radius: 0rem;
}

.btnTransform:active,
.imgTransform:active {
  transform: translate(-1px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2);
}

#alert {
  padding: 0;
  margin-bottom: -10px;
  margin-top: 1rem;
}

/* #spin {
  animation-name: moveInLeft;
  animation-duration: 4s;
} */

img.rotateImg {
  animation-name: moveInLeft;
  animation-duration: 3s;
}

/* img {
  transform-style: preserve-3d;
  transform: perspective(1000px);
} */
img.item {
  transition: 0.5s;
  filter: brightness(0.7);

  margin-right: 0.3rem;
  margin-bottom: 0.3rem;
}
img.item:hover {
  filter: brightness(1);
  transform: translateZ(200px);
}

.bio em {
  font-weight: bold;
  font-size: 120%;
  width: 100vmax;
}

.bio .events {
  animation-name: moveFromTop;
  animation-duration: 4s;
  padding-right: 0;
  margin-right: 0;
  overflow: hidden;
}

#lisaImage {
  height: 25px;
}

#anniversary {
  animation-name: moveFromTop;
  animation-duration: 4s;
  background-image: url(./IMG_0473darker.jpg);
  border-radius: 0;
}

@keyframes moveInLeft {
  0% {
    opacity: 0;
    transform: translateX(-100px) rotate(180deg);
  }

  80% {
    transform: translateX(10px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

.modal-title {
  text-align: center;
}

.events {
  width: 75%;
}

.modal-open {
  overflow: auto;
}
.modal-content {
  background-color: hsl(0, 89%, 48%);
}

@media screen and (max-width: 580px) {
  .navv {
    margin-right: 0;
  }
  .larger {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  #noscroll::-webkit-scrollbar {
    display: none;
  }
  #anniversary {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
  .events {
    width: 75%;
  }
  #alert {
    margin-left: 1rem;
    padding-right: 1rem;
  }
  .container-fluid img {
    max-width: 100dvw;
  }
  .studentPhotos {
    max-width: 100vw;
  }
  .background {
    max-width: 99vw;
  }
  #cover {
    padding: 0;
    margin: 0;
    height: 60vh;
  }
  h1 em {
    margin-top: 1rem;

    font-size: 3rem;
  }
  p.opening em {
    font-size: 1rem;
  }
  .btnTransform {
    text-align: left;
    margin-left: 2rem;
    padding-left: 0.5rem;
  }
  .btnTransform:hover {
    box-shadow: 2 5px 5px rgba(0, 0, 0, 0.2);
  }
}

@keyframes scrolling {
  to {
    transform: translate(calc(-50%));
  }
}

@keyframes moveInRight_mod {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInRight {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }

  80% {
    transform: translateX(-10px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveFromTop {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }

  80% {
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes moveInButton {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

.fade-in-image {
  animation: fadeIn 5s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
/*try rottat effect also*/

.text-light,
.video-animate {
  animation-name: moveInLeft;
  animation-duration: 5s;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  animation: fadeIn 10s;
}

p.lineStyle {
  line-height: 1.5;
}

.background-animation {
  animation: moveInButton 1s ease-out 0.75s;
  animation-fill-mode: backwards;
}

.navv {
  max-width: 100vw;
}

.cardlink:hover {
  font-size: 1.25rem;
  background-color: rgb(217, 83, 79, 0.4);
}

/* ::webkit-scrollbar {
  width: 2em;
}

::webkit-scrollbar-track {
  background: hsl(120 50% 50% / 1);
  border-radius: 100vw;
}

::-webkit-scrollbar-thumb {
  background: hsl(120 100% 20% / 1);
  border-radius: 100vw;
} */
